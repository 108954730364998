import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import NavbarLogo from "../images/navbar-logo.png";
import MainLogo from "../images/sos_logo.png";
import InstagramLogoLarge from "../images/instagram-logo-new.png";
import TwitterLogoLarge from "../images/twitter-logo-new.png";
import FacebookLogoLarge from "../images/facebook-logo-new.png";
import linkedin from "../images/linkedin.png";

import "./NewNavbar.css";

const NewNavbar = () => {
    const [navbarHide, setNavbarHide] = useState(true);
    let location = useLocation();

    const toggleNavbar = () => {
        // console.log("toggleNavbar");
        setNavbarHide(!navbarHide);
    };

    const [aboutUsDropdownHidden, setAboutUsDropdownHidden] = useState(true);
    const [siDropdownHidden, setsiDropdownHidden] = useState(true);

    const toggleDropdown = () => {
        setTimeout(() => {
            console.log("toggling")
        }, 800)

        setAboutUsDropdownHidden(!aboutUsDropdownHidden)
    };
    const toggleDropdown2 = () => {
        setTimeout(() => {
            console.log("toggling")
        }, 800)
        setsiDropdownHidden(!siDropdownHidden);
    };


    const [mobileDropdownHidden, setMobileDropdownHidden] = useState(true);
    const [mobileDropdownHidden2, setMobileDropdownHidden2] = useState(true);

    const toggleMobileDropdown = () => {
        setMobileDropdownHidden(!mobileDropdownHidden);
    };
    const toggleMobileDropdown2 = () => {
        setMobileDropdownHidden2(!mobileDropdownHidden);
    };

    console.log(navbarHide);
    return (
        <div className="navbarwrapper">
            <h6 className="hidden md:block">
                HERMANN GMEINER EDUCATIONAL INSTITUTIONS
            </h6>
            <div className="navbarContainer">
                <div
                    data-aos="fade"
                    className="w-full flex text-white justify-center items-center px-8  z-10"
                >
                    {/* <Link to="/home" className="h-14">
          <img className="h-full" src={MainLogo} alt="SOS" />
        </Link> */}
                    <div
                        className="lg:hidden flex justify-center items-center"
                        onClick={toggleNavbar}
                    >
                        <h6 className="block md:hidden">
                            HERMANN GMEINER EDUCATIONAL INSTITUTIONS
                        </h6>
                        <div className="h-4">
                            <img className="h-full" src={NavbarLogo} alt="" />
                        </div>
                    </div>
                    <div className="hidden text-md lg:flex gap-3  font-medium tracking-wider h-10 items-center text-center">
                        <Link
                            to="/"
                            className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                        >
                            Home
                        </Link>
                        <span>|</span>
                        <div
                            className="h-full flex items-center drop-shadow-md relative"
                            onMouseEnter={toggleDropdown}
                            onMouseLeave={toggleDropdown}


                        >
                            <div className="hover:underline hover:scale-105 transition-all">
                                About Us
                            </div>
                            <div
                                className={
                                    "p-3 border-2 rounded-lg absolute transition-all duration-300 flex top-8 flex-col gap-2 backdrop-filter backdrop-blur w-[280px] z-10 -left-1/2 mt-4 bg-[#00000055] text-left " +
                                    (aboutUsDropdownHidden ? "hidden " : "")
                                }
                            >
                                <Link
                                    to="/about-the-institution"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> About the Institution"}
                                </Link>
                                <Link
                                    to="/success-stories"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> Success Stories"}
                                </Link>
                                <Link
                                    to="/media-coverage"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> Media Coverage"}
                                </Link>
                                {/* changes made in the below line  */}
                                <Link
                                    to="/achievements-and-laurels"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> Achievements and Laurels"}
                                </Link>

                                <Link
                                    to="/alumni"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> Illustrious Alumni"}
                                </Link>
                                {/* <Link
                                    href="/success-stories"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                    
                                >
                                    {"> Success Stories"}
                                </Link> */}
                                {/* <Link
                                    to="/about-us"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> 56 years of SOS"}
                                </Link> */}

                            </div>
                        </div>
                        <span>|</span>

                        <a
                            href="/home#schools-and-institions"
                            className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                        >
                            Schools & Institutions
                        </a>
                        <span>|</span>
                        <div
                            className="h-full flex items-center drop-shadow-md relative"
                            onMouseEnter={toggleDropdown2}
                            onMouseLeave={toggleDropdown2}
                        >
                            <div className="hover:underline hover:scale-105 transition-all">
                                Academics
                            </div>
                            <div
                                className={
                                    "p-3 border-2 rounded-lg absolute transition-all duration-300 flex top-8 flex-col gap-2 backdrop-filter backdrop-blur w-[280px] z-10 -left-1/2 mt-4 bg-[#00000055] text-left " +
                                    (siDropdownHidden ? "hidden " : "")
                                }
                            >

                                <Link
                                    to="/NEP"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {">NEP Ready Schools "}
                                    {/* commenting to landmark */}
                                    
                                </Link>
                                <Link
                                    to="/pedagogy"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> Pedagogical Approach"}
                                </Link>
                                <Link
                                    to="/life-skills"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> Life Skill Curriculum"}
                                </Link>
                                <Link
                                    to="/skill-vocational"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> Skill and Vocational Subjects"}
                                </Link>
                                <Link
                                    to="/beyound-curriculum"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> Beyond Curriculum"}
                                </Link>
                                <Link
                                    to="/NCC"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> NCC, School Band"}
                                </Link>
                                <Link
                                    to="/Institutional-Planner"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> Institutional Planner"}
                                </Link>
                                <Link
                                    to="/booklist"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> Booklist"}
                                </Link>
                                <Link
                                    to="/alumni"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> Results"}
                                </Link>
                                <Link
                                    to="/Institutional-Planner"
                                    className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                >
                                    {"> Holiday List"}
                                </Link>

                            </div>
                        </div>

                        <span>|</span>
                        {/* turning Link tag into a div tag  */}
                        <div
                            to="/Blogs"
                            className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                        >
                            Contact Us
                        </div>
                        <span>|</span>
                        <Link
                            to="/Blogs"
                            className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                        >
                            Blogs
                        </Link>
                        <span>|</span>
                        <div
                            to="/Blogs"
                            className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                        >
                            Gallery
                        </div>
                        <span>|</span>
                        <Link
                            to="/careers"
                            className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                        >
                            Career
                        </Link>
                        <span>|</span>
                        <div
                            to="/Blogs"
                            className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                        >
                            Events
                        </div>
                        <span>|</span>

                        <div className="flex">
                            <div className="h-4 md:h-6 mr-3  cursor-pointer">
                                <img
                                    className="h-full"
                                    src={FacebookLogoLarge}
                                    alt=""
                                    onClick={() =>
                                        window.open(
                                            "https://www.facebook.com/profile.php?id=100092651126160",
                                            "_blank"
                                        )
                                    }
                                />
                            </div>
                            <div className="h-4 md:h-6 mr-3  cursor-pointer">
                                <img
                                    className="h-full"
                                    src={InstagramLogoLarge}
                                    alt=""
                                    onClick={() =>
                                        window.open(
                                            "https://www.instagram.com/hermanngmeinerinstitutions/",
                                            "_blank"
                                        )
                                    }
                                />
                            </div>
                            <div className="h-4 md:h-6  cursor-pointer">
                                <img
                                    className="h-full"
                                    src={linkedin}
                                    alt=""
                                    onClick={() =>
                                        window.open(
                                            "https://www.linkedin.com/company/herman-gmeiner-educational-institutions/",
                                            "_blank"
                                        )
                                    }
                                />
                            </div>
                        </div>
                        {/* {location.pathname === "/" ||
                    location.pathname === "/home" ? (
                        <a href="#alumni" className="hover:underline">
                            Alumni
                        </a>
                    ) : (
                        <Link to="/alumni" className="hover:underline">
                            Alumni
                        </Link>
                    )} */}
                    </div>
                </div>
            </div>
            <div
                className={
                    "z-10 fixed top-0 h-screen flex w-screen lg:hidden duration-300" +
                    (navbarHide ? " -right-full" : " right-0")
                }
            >
                <div
                    className="h-screen w-3/12 md:w-1/2 backdrop-blur-sm"
                    onClick={toggleNavbar}
                ></div>
                <div className="bg-[#1e9ad6] text-white text-base h-screen w-9/12 md:w-1/2 shadow flex flex-col pt-12">
                    <Link
                        to="/"
                        className="py-5 px-8 font-semibold border-b"
                        onClick={toggleNavbar}
                    >
                        Home
                    </Link>
                    <div
                        className="py-5 px-8 font-semibold border-b border-t"
                        onClick={toggleMobileDropdown}
                    >
                        <div>About Us</div>
                        <div
                            className={
                                "p-3 border-1 rounded-lg transition-all duration-300 flex flex-col gap-2 backdrop-filter backdrop-blur z-10 -left-1/2 mt-4 bg-[#00000011] " +
                                (mobileDropdownHidden ? "hidden" : "")
                            }
                        >
                            <Link
                                to="/about-the-institution"
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                                onClick={toggleNavbar}
                            >
                                {"> About the Institution"}
                            </Link>
                            <Link
                                to="/success-stories"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> Success Stories"}
                            </Link>
                            <Link
                                to="/media-coverage"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> Media Coverage"}
                            </Link>
                            <div
                                to="/about-the-institution"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> Achievements and Laurels"}
                            </div>
                            <Link
                                to="/alumni"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> Illustrious Alumni"}
                            </Link>
                        </div>
                    </div>
                    <div
                        className="py-5 px-8 font-semibold border-b border-t"
                        onClick={toggleMobileDropdown2}
                    >
                        <div>Academics</div>
                        <div
                            className={
                                "p-3 border-1 rounded-lg transition-all duration-300 flex flex-col gap-2 backdrop-filter backdrop-blur z-10 -left-1/2 mt-4 bg-[#00000011] " +
                                (mobileDropdownHidden2 ? "hidden" : "")
                            }
                        >
                            <Link
                                to="/NEP"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {">NEP Ready Schools "}
                            </Link>
                            <Link
                                to="/pedagogy"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> Pedagogical Approach"}
                            </Link>
                            <Link
                                to="/life-skills"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> Life Skill Curriculum"}
                            </Link>
                            <Link
                                to="/skill-vocational"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> Skill and Vocational Subjects"}
                            </Link>
                            <Link
                                to="/beyound-curriculum"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> Beyond Curriculum"}
                            </Link>
                            <Link
                                to="/NCC"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> NCC, School Band"}
                            </Link>
                            <Link
                                to="/Institutional-Planner"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> Institutional Planner"}
                            </Link>
                            <Link
                                to="/booklist"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> Booklist"}
                            </Link>
                            <Link
                                to="/alumni"
                                onClick={toggleNavbar}
                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> Results"}
                            </Link>
                            <Link
                                to="/Institutional-Planner"
                                onClick={toggleNavbar}

                                className="hover:underline hover:scale-105 transition-all duration-100 drop-shadow-md"
                            >
                                {"> Holiday List"}
                            </Link>
                        </div>
                    </div>
                    <a
                        href="/home#schools-and-institions"
                        className="py-5 px-8 font-semibold border-b"
                        onClick={toggleNavbar}
                    >
                        Schools & Institutions
                    </a>

                    {/* {location.pathname === "/" ||
                    location.pathname === "/home" ? (
                        <a
                            href="#alumni"
                            className="py-5 px-8 font-semibold border-b"
                            onClick={toggleNavbar}
                        >
                            Alumni
                        </a>
                    ) : (
                        <Link
                            to="/alumni"
                            className="py-5 px-8 font-semibold border-b"
                            onClick={toggleNavbar}
                        >
                            Alumni
                        </Link>
                    )} */}

                    <Link
                        to="/media-coverage"
                        className="py-5 px-8 font-semibold border-b"
                        onClick={toggleNavbar}
                    >
                        Contact Us
                    </Link>

                    <Link
                        to="/blogs"
                        className="py-5 px-8 font-semibold border-b"
                        onClick={toggleNavbar}
                    >
                        Blogs
                    </Link>
                    <Link
                        to="/Institutional-Planner"
                        className="py-5 px-8 font-semibold border-b"
                        onClick={toggleNavbar}
                    >
                        Gallery
                    </Link>
                    <Link
                        to="/careers"
                        className="py-5 px-8 font-semibold border-b"
                        onClick={toggleNavbar}
                    >
                        Career
                    </Link>
                    <Link
                        to="/Institutional-Planner"
                        className="py-5 px-8 font-semibold border-b"
                        onClick={toggleNavbar}
                    >
                        Events
                    </Link>

                    <div className="flex py-5 px-8">
                        <div className="h-8 md:h-10 mr-3  cursor-pointer">
                            <img
                                className="h-full"
                                src={FacebookLogoLarge}
                                alt=""
                                onClick={() =>
                                    window.open(
                                        "https://www.facebook.com/profile.php?id=100092651126160",
                                        "_blank"
                                    )
                                }
                            />
                        </div>
                        <div className="h-8 md:h-10 mr-3  cursor-pointer">
                            <img
                                className="h-full"
                                src={InstagramLogoLarge}
                                alt=""
                                onClick={() =>
                                    window.open(
                                        "https://www.instagram.com/hermanngmeinerinstitutions/",
                                        "_blank"
                                    )
                                }
                            />
                        </div>
                        <div className="h-8 md:h-10  cursor-pointer">
                            <img
                                className="h-full"
                                src={linkedin}
                                alt=""
                                onClick={() =>
                                    window.open(
                                        "https://www.linkedin.com/company/herman-gmeiner-educational-institutions/",
                                        "_blank"
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewNavbar;
