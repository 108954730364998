import students from "../images/Group.svg";
import school from "../images/school.svg";
import boards from "../images/boards.svg";
import college from "../images/college.svg";
import nursing from "../images/nursing.svg";
import vocational from "../images/vocational.svg";
import stem from "../images/stem.svg";
import bed from "../images/bed.svg";
import skill from "../images/skill.svg";
import life from "../images/life-skill.svg";
const InstituteValues = () => {
    const Card = ({ title, border, bg, icon }) => {
        return (
            <div
                style={{
                    boxShadow: "rgb(0 0 0 / 25%) 10px 8px 4px",
                    margin: "30px 10px",
                    width: "240px",
                }}
                className={`h-46 border-8 ${border} rounded-2xl overflow-hidden`}
            >
                <div className={`flex py-2 justify-center ${bg}`}>{icon}</div>

                <div className="px-4 py-4">
                    <div className="font-bold text-xl mb-2">{title}</div>
                </div>
            </div>
        );
    };

    return (
        <div data-aos="fade" className="w-full flex  	flex-col p-12 md:p-20">
            <div
                data-aos="fade-left"
                data-aos-delay={300}
                className="h-[5px] w-32 bg-[#1e9ad6] mx-auto sm:mx-0"
            ></div>
            <div
                data-aos="fade-left"
                data-aos-delay={500}
                className="font-bold text-center md:text-left  text-2xl md:text-4xl my-8 md:mb-12"
            >
                Center of Excellence
            </div>
            <div className="flex justify-center text-center  flex-wrap flex-col ">
                <div className="values-row flex justify-center md:justify-between  ">
                    <div className="icons-cont">
                        <img
                            src={students}
                            alt="icon"
                            className="values-icon "
                        />
                        <div className="values-title ">7500+ Students</div>
                    </div>
                    <div className="icons-cont">
                        <img src={school} alt="icon" className="values-icon" />
                        <div className="values-title">8 Schools</div>
                    </div>
                    <div className="icons-cont">
                        <img src={boards} alt="icon" className="values-icon" />
                        <div className="values-title">CBSE/State Board</div>
                    </div>
                    <div className="icons-cont">
                        <img src={college} alt="icon" className="values-icon" />
                        <div className="values-title">Degree College</div>
                    </div>
                    <div className="icons-cont">
                        <img src={nursing} alt="icon" className="values-icon" />
                        <div className="values-title">Nursing Colleges</div>
                    </div>
                </div>
                <div className="values-row flex justify-center md:justify-between">
                    <div className="icons-cont">
                        <img
                            src={vocational}
                            alt="icon"
                            className="values-icon"
                        />
                        <div className="values-title">
                            Vocational Training Centres
                        </div>
                    </div>
                    <div className="icons-cont">
                        <img src={stem} alt="icon" className="values-icon" />
                        <div className="values-title">Stem Labs</div>
                    </div>
                    <div className="icons-cont">
                        <img src={bed} alt="icon" className="values-icon" />
                        <div className="values-title">NEP Ready Schools</div>
                    </div>
                    <div className="icons-cont">
                        <img src={skill} alt="icon" className="values-icon" />
                        <div className="values-title">21st Century Skills</div>
                    </div>
                    <div className="icons-cont">
                        <img src={life} alt="icon" className="values-icon" />
                        <div className="values-title">
                            Life Skill Curriculum
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InstituteValues;