
import Marquee from "react-fast-marquee";


const ResultMoving = () => {
    return (
        <Marquee>
            <p> <span className="mx-2">★★★</span> Hermann Gmeiner Educational Institutions - Class X Results 2022-23 (100% Pass) ● Samayak Gaur, Bhimtal (96.8%) ● Somya Upreti, Bhimtal (96.8%) ● Ayush Yadav, Varanasi (96.8%) ● Chahat Khatri, Jammu (96.2%) ● Riddhishree Nayak, Faridabad (95.4%) <span className="mx-2">★★★</span> </p>
            <p> Hermann Gmeiner Educational Institutions - Class Xll Results 2022-23 (100% Pass) ● Jamil Ahamad, Faridabad (95.4%) ● Kshitiz Singh (95.4%) ● Anshika Maurya, Varanasi (95.2%) ● Mayur Kitchloo, Jammu (92.6%)</p>
        </Marquee>
    )
}
export default ResultMoving