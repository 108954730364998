// import {nep_data} from "../variable"
import Skillvocational from "./Skillvocational";

import { FaSchool } from "react-icons/fa";
import NCC from "./NCC";
import { useLayoutEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

// components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// pages
import HomePage from "./HomePage";
import InfrastructurePage from "./InfrastructurePage";
import AboutUsPage from "./AboutUsPage";
import OurBoardPage from "./OurBoardPage";
import ActivitiesPage from "./ActivitiesPage";
import AwardsPage from "./AwardsPage";
import ParentsEngagePage from "./ParentsEngagePage";
import AlumniPage from "./AlumniPage";
import SocialImpactPage from "./SocialImpactPage";
import PedagogyPage from "./PedagogyPage";
import VTCPage from "./VTCPage";
import SuccessStoriesPage from "./SuccessStoriesPage";
import MediaCoveragePage from "./MediaCoveragePage";
import BeyoundCurriculum from "../components/BeyoundCurriculum";
import NewNavbar from "../components/NewNavbar";
import AboutTheInstitutionsPage from "./AboutTheInstitutionsPage";
import Blogs from "../components/Blogs/blogs";
import BlogPostPagee from "../components/Blogs/BlogPostPage";
import Careers from "./Careers"
import InstitutionalPlanner from "../components/InstitutionalPlanner";
import Booklist from "../components/Booklist";
import NEP from "./NEP";
import Lifeskill from "./Lifeskill";
const App = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    AOS.init({
      offset: 150,
      duration: 500,
      startEvent: "DOMContentLoaded",
      once: false,
      mirror: true,
      anchorPlacement: "bottom-top",
    });
  }, []);

  // coming out of variable.js 
  let nep_data = [
    {
      id: 1,
      icon: <FaSchool />,
      description:
        "Hermannn Gmeiner School pedagogy strives to make the schooling process more insightful. It has expanded the horizon of learning and made it more interesting, and motivating. The aim is to focus on the all-round development of the students rather than focusing only on the theoretical part. This makes them ready to face the outer world.",
      extra_description: ["NEP"],
    }];

let  skill_vocational_data = [
      {
        description: [
          `Vocational subjects include more hands-on training and less of the traditional academic learning. We at Hermann Gmeiner School focus on integrating art with the curriculum for all the students to appreciate the aesthetics and visual performing arts. Practical or first-hand knowledge about the market or trade is a very essential part of the vocational training that we provide to the learners.`,
          `We always prioritize our students and for that we offer differentiated teaching-learning activities as well as personalized learning. All the varied Skill and Vocational subjects are included and taught at our institution. A team of dignified and intellectual teachers is always on the stand to help the students master the subjects.`,
          `Emulating the skill-based model of education as per NEP 2020 and CBSE guidance, we have also introduced the skill subjects at the secondary level. Choosing any one Skill subject at this level can help the child to pursue what truly interests or pleases him or her. This liberty promotes a sense of self-esteem in accepting one’s talents and strengths.`,
        ],
      }]

  return (
    <Router>
      <div className="font-montserrat">
        <NewNavbar />
        {/* <Navbar /> */}
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="*" element={<HomePage />} />
          <Route path="/infrastructure" element={<InfrastructurePage />} />
          <Route path="/activities" element={<ActivitiesPage />} />
          <Route path="/awards" element={<AwardsPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route
            path="/about-the-institution"
            element={<AboutTheInstitutionsPage />}
          />
          <Route path="/about-us/our-board" element={<OurBoardPage />} />
          <Route path="/parents-engagement" element={<ParentsEngagePage />} />
          <Route path="/pedagogy" element={<PedagogyPage />} />
          <Route
            path="/social-impact-programmes"
            element={<SocialImpactPage />}
          />
          <Route path="/alumni" element={<AlumniPage />} />
          <Route path="/vtc" element={<VTCPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/success-stories" element={<SuccessStoriesPage />} />
          <Route path="/media-coverage" element={<MediaCoveragePage />} />
          <Route path="beyound-curriculum" element={<BeyoundCurriculum />} />
          <Route path="/blogs" element={<Blogs />} />

          <Route
            path="/Institutional-Planner"
            element={<InstitutionalPlanner />}
          />



<Route path="/NCC" element={<NCC />} />
          <Route path="/booklist" element={<Booklist />} />
          <Route path="/post/:id" element={<BlogPostPagee />} />
          <Route path = "/careers" element={<Careers />} />
          <Route path = "/NEP" element = {<NEP />} />

          <Route path = "/life-skills" element = {<Lifeskill />} />
          <Route
            path="/skill-vocational"
            element={
             <Skillvocational />
            }
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
// to be used instead of lifeskills in the routing 

// {<NEP  cover={nep_cover}
//                 flaticon={<FaSchool style={{ fontSize: 50 }} />}
//                 title="NEP 2020 Ready School"
//                 // icon={<FaSchool />}
//                 // sidebar_data={academics_sidenav}
//                 data={nep_data}
//                 side_sub_heading={true}
//                 side_heading="Academics & Curriculum"
//                 link="nep" />}




